/* tslint:disable */
/* eslint-disable */
/**
 * RaidSIMP OpenAPI Spec
 * Manage your raid teams, enhanced with data from the Blizzard API.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RaidDifficultyLockout
 */
export interface RaidDifficultyLockout {
    /**
     * 
     * @type {string}
     * @memberof RaidDifficultyLockout
     */
    difficulty: RaidDifficultyLockoutDifficultyEnum;
    /**
     * 
     * @type {number}
     * @memberof RaidDifficultyLockout
     */
    bossesKilled: number;
    /**
     * 
     * @type {number}
     * @memberof RaidDifficultyLockout
     */
    bossesTotal: number;
}

/**
* @export
* @enum {string}
*/
export enum RaidDifficultyLockoutDifficultyEnum {
    LookingForRaid = 'Looking For Raid',
    Normal = 'Normal',
    Heroic = 'Heroic',
    Mythic = 'Mythic'
}

export function RaidDifficultyLockoutFromJSON(json: any): RaidDifficultyLockout {
    return RaidDifficultyLockoutFromJSONTyped(json, false);
}

export function RaidDifficultyLockoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): RaidDifficultyLockout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'difficulty': json['difficulty'],
        'bossesKilled': json['bossesKilled'],
        'bossesTotal': json['bossesTotal'],
    };
}

export function RaidDifficultyLockoutToJSON(value?: RaidDifficultyLockout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'difficulty': value.difficulty,
        'bossesKilled': value.bossesKilled,
        'bossesTotal': value.bossesTotal,
    };
}


