/* tslint:disable */
/* eslint-disable */
/**
 * RaidSIMP OpenAPI Spec
 * Manage your raid teams, enhanced with data from the Blizzard API.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRaidTeamDto
 */
export interface CreateRaidTeamDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRaidTeamDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRaidTeamDto
     */
    region: CreateRaidTeamDtoRegionEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateRaidTeamDtoRegionEnum {
    Us = 'us',
    Eu = 'eu',
    Kr = 'kr',
    Tw = 'tw',
    Cn = 'cn'
}

export function CreateRaidTeamDtoFromJSON(json: any): CreateRaidTeamDto {
    return CreateRaidTeamDtoFromJSONTyped(json, false);
}

export function CreateRaidTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRaidTeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'region': json['region'],
    };
}

export function CreateRaidTeamDtoToJSON(value?: CreateRaidTeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'region': value.region,
    };
}


