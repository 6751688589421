/* tslint:disable */
/* eslint-disable */
/**
 * RaidSIMP OpenAPI Spec
 * Manage your raid teams, enhanced with data from the Blizzard API.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Raider
 */
export interface Raider {
    /**
     * 
     * @type {string}
     * @memberof Raider
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Raider
     */
    characterId: number;
    /**
     * 
     * @type {string}
     * @memberof Raider
     */
    characterName: string;
    /**
     * 
     * @type {string}
     * @memberof Raider
     */
    realm: string;
    /**
     * 
     * @type {string}
     * @memberof Raider
     */
    role: RaiderRoleEnum;
    /**
     * 
     * @type {Date}
     * @memberof Raider
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Raider
     */
    updatedAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum RaiderRoleEnum {
    Tank = 'tank',
    Healer = 'healer',
    Melee = 'melee',
    Ranged = 'ranged'
}

export function RaiderFromJSON(json: any): Raider {
    return RaiderFromJSONTyped(json, false);
}

export function RaiderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Raider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'characterId': json['characterId'],
        'characterName': json['characterName'],
        'realm': json['realm'],
        'role': json['role'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function RaiderToJSON(value?: Raider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'characterId': value.characterId,
        'characterName': value.characterName,
        'realm': value.realm,
        'role': value.role,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


