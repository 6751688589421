/* tslint:disable */
/* eslint-disable */
/**
 * RaidSIMP OpenAPI Spec
 * Manage your raid teams, enhanced with data from the Blizzard API.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRaiderDto
 */
export interface CreateRaiderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRaiderDto
     */
    characterName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRaiderDto
     */
    realm: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRaiderDto
     */
    role: CreateRaiderDtoRoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateRaiderDtoRoleEnum {
    Tank = 'tank',
    Healer = 'healer',
    Melee = 'melee',
    Ranged = 'ranged'
}

export function CreateRaiderDtoFromJSON(json: any): CreateRaiderDto {
    return CreateRaiderDtoFromJSONTyped(json, false);
}

export function CreateRaiderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRaiderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'characterName': json['characterName'],
        'realm': json['realm'],
        'role': json['role'],
    };
}

export function CreateRaiderDtoToJSON(value?: CreateRaiderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'characterName': value.characterName,
        'realm': value.realm,
        'role': value.role,
    };
}


