/* tslint:disable */
/* eslint-disable */
/**
 * RaidSIMP OpenAPI Spec
 * Manage your raid teams, enhanced with data from the Blizzard API.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SearchResultDto,
    SearchResultDtoFromJSON,
    SearchResultDtoToJSON,
} from '../models';

export interface SearchControllerSearchRequest {
    region: string;
    characterName: string;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * Search for WoW characters in a region.
     */
    async searchControllerSearchRaw(requestParameters: SearchControllerSearchRequest): Promise<runtime.ApiResponse<Array<SearchResultDto>>> {
        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling searchControllerSearch.');
        }

        if (requestParameters.characterName === null || requestParameters.characterName === undefined) {
            throw new runtime.RequiredError('characterName','Required parameter requestParameters.characterName was null or undefined when calling searchControllerSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.characterName !== undefined) {
            queryParameters['characterName'] = requestParameters.characterName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchResultDtoFromJSON));
    }

    /**
     * Search for WoW characters in a region.
     */
    async searchControllerSearch(requestParameters: SearchControllerSearchRequest): Promise<Array<SearchResultDto>> {
        const response = await this.searchControllerSearchRaw(requestParameters);
        return await response.value();
    }

}
